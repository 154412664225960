import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Container } from "reactstrap";
import React, { Component } from "react"
import { FaSearch, FaArrowRight } from "react-icons/fa";
import styled from "styled-components";
import gens3d from "../images/3DGENS_LOGO.png"
import gens3dwhite from "../images/3dgens-logo_480px_whiteall.png"
import "../styles/header.css"
import LinkButtonSecondary from "./buttons/LinkButtonSecondary";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";



const NavBarStyled = styled(Navbar)`
&&&{
    .nav-link{
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      color: #17a99e;
      margin-left: 30px;
      :hover{
        color: #007068;
      }
      :focus{
        color: #17a99e;
      }
    }
    .dropdown-item{
      color: #17a99e;
    }
  }
  `
const SmallBar = styled.div`
  background-color: #ccc;
  height: 1rem;
`
const LogoBox = styled.div`
  margin-top: 10px;
  margin-left: 30px;
`
const LogoHeader = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 0;
`
const NavBarred = styled.div`
  background-color: #323232;
  // text-align: center;
  padding: 10px 0;

  a{
    font-size: 16px;
    color: white;
    padding: 10px 15px;
    text-decoration: none;
  }
  a:hover{
    color: #1ae99e
  }
`
const DropdownBox = styled.div`
  display: inline-block;
  font-size: 16px;
  color: #fff;
  .dropbutton{
    color: white;
    background-color: inherit;
    font-weight: bold;
    :hover{
      color: #1ae99e;
    }

    
  }
  .dropcontent{
    display: none;
    position: absolute;
    z-index: 2;
    a{
      color: #fff;
      background-color: #000;
      margin: 0 5px;
      display: block;
      text-align left;
      text-decoration: none;
      :hover{
        color: 1ae99e;
        background-color: #313131;
      }
    }
  }
  :hover{
    .dropcontent{
      display: block;
    }
  }
  .flexbox{
    display: flex;
    flex-direction: row;
    background-color: #000;
    padding: 10px;
    p{
      text-align: center;
      margin-bottom: 0;
    }
    .box2{
      border-left: solid 1px #fff;
    }
  }
`
const TopBarTOP = styled.div`
padding: 10px 50px;
text-align: right;
background-color: #000;
font-size: 12px;
.buybutton{
    display: none;
    color: inherit;
    background-color: #fff;
    text-decoration: none;
    padding: 5px 10px;
    border: 1px solid #234678;
    border-radius: 5px;
    :hover{
      color: #fff;
      background-color: #234678;
    }
  }
  .contactbutton{
    color: #fff;
    background-color: #234678;
    text-decoration: none;
    padding: 5px 10px;
    margin: 0px 30px;
    border: 1px solid #234678;
    border-radius: 5px;
    :hover{
      color: #000;
      background-color: #f7f7f7;;
    }
  }
`
const NavLine = styled.hr`
  padding: 0;
  margin: 0;
`

export default class Navigation extends React.Component {

  render() {
    return (
      <header>
        {/* <SmallBar/> */}
        {/* <LogoBox>        </LogoBox> */}
        <TopBarTOP>
          <a href="https://www.my3dpm.com/" target="_blank" rel="noopener noreferrer" className="buybutton">
            Buy at My3dpm
          </a>
          <a href="/contact" className="contactbutton">
            Contact
          </a>
        </TopBarTOP>
        {/* <hr/> */}
        <NavBarred>
          <a href="/" style={{textDecoration:"none"}}>
            <LogoHeader src={gens3dwhite} alt="logo" />
          </a>
          <DropdownBox>
            <div className="dropbutton">
              <a href="/printer">3D Printer</a>
            </div>
              <div className="dropcontent">
                <div className="flexbox">
                  <div className="box1">
                    <p><u>Printers</u></p>
                    <a href="/metalprinter">3D Metal Printer</a>
                    <a href="/polyprinter">3D Polymer Printer</a>
                  </div>
                  <div className="box2">
                    <p><u>Materials</u></p>
                    <a href="/metalmaterial">3D Metal Material</a>
                  </div>
                </div>
                {/* <a href="/bioprinter">3D Bio Printers</a> */}
              </div>
          </DropdownBox>
          
          <DropdownBox>
            <div className="dropbutton">
              <a href="/scanner">3D Scanner</a>
            </div>
              <div className="dropcontent">
                <div className="flexbox">
                  <div className="box1">
                    <a href="/scanner">3D Scanning Device</a>
                    {/* <a href="/reversescan">Reverse Engineering Service</a> */}
                  </div>
                </div>
              </div>
          </DropdownBox>
          <DropdownBox>
            <div className="dropbutton">
              <a href="/training">Training</a>
            </div>
              <div className="dropcontent">
                <div className="flexbox">
                  <div className="box1">
                    <a href="/training">3D Printing Training</a>
                  </div>
                </div>
              </div>
          </DropdownBox>
          <DropdownBox>
            <div className="dropbutton">
              <a href="/software-fusion">Software</a>
            </div>
              <div className="dropcontent">
                <div className="flexbox">
                  <div className="box1">
                    <a href="/software-fusion">Autodesk Fusion</a>
                  </div>
                </div>
              </div>
          </DropdownBox>
          <DropdownBox>
            <div className="dropbutton">
              <a href="/consultation">Product</a>
            </div>
              <div className="dropcontent">
                <div className="flexbox">
                  <div className="box1">
                    <a href="/engineering">Engineering Innovation</a>
                    <a href="/medical">Medical Application</a>
                    <a href="/digital-dentistry">Digital Dentistry</a>
                    <a href="/consultation">Project Consultation</a>
                    <a href="/research">Research and Development</a>
                  </div>
                </div>
              </div>
          </DropdownBox>
          <DropdownBox>
            <div className="dropbutton">
              <a href="/Contact">Resource</a>
            </div>
              <div className="dropcontent">
                <div className="flexbox">
                  <div className="box1">
                    <a href="/about">About Us</a>                         
                    <a href="/stories">News</a>
                    <a href="/contact">Contact</a>
                  </div>
                </div>
              </div>
          </DropdownBox>
        </NavBarred>
        <NavLine/>



        {/* <Container>
        <NavBarStyled collapseOnSelect expand="lg">
        <NavBarStyled.Brand href="/">
            <LogoHeader src={gens3d} alt="logo"/>
          </NavBarStyled.Brand>
          <NavBarStyled.Toggle aria-controls="responsive-navbar-nav" />
          <NavBarStyled.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <NavDropdown title="What is AM" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/about/#about">AM Benefits</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Who We Are" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/about/#about">About Us</NavDropdown.Item>
                <NavDropdown.Item href="/about/#iso">AM Equipment</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Product & Services" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/medical">Medical</NavDropdown.Item>
                <NavDropdown.Item href="/digital-dentistry">Digital Dentistry</NavDropdown.Item>
                <NavDropdown.Item href="/engineering">Engineering</NavDropdown.Item>
                <NavDropdown.Item href="/training">Training</NavDropdown.Item>
                <NavDropdown.Item href="/research">Research</NavDropdown.Item>
                <NavDropdown.Item href="/printer">3D Printer</NavDropdown.Item>
                <NavDropdown.Item href="/scanner">3D Scanner</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/career">Career</Nav.Link>
              <Nav.Link href="/about/#contact">Contact Us</Nav.Link>
            </Nav>
          </NavBarStyled.Collapse>
        </NavBarStyled>

        </Container> */}

      </header>
    );
  }
}

